.content__inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
@include media("<=1300px") {
  max-width: 945px;
  margin: 0 auto;
  column-gap: 20px;
}
@include media("<=1024px") {
    max-width: 750px;
}
@include media("<=810px") {
    max-width: 90%;
} 
}



.breadcrumbs {
    display: flex;
    column-gap: 5px;
}

.breadcrumbs__item {

}

.breadcrumbs__link {
    text-transform: lowercase;
  text-decoration: none;
  display: block;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  padding: 0 8px;
border-radius: 20px;
    background: rgb(155, 93, 229);
    &-red {
        background: #FF2A28;
    }
}

.main__date {
    font-style: italic;
    font-size: 12px;
    font-weight: 500;
    span {
        opacity: .5;
        padding-right: 4px;
    }
}

.article__responce {
    margin-left: auto;
    display: flex;
    column-gap: 4px;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.article__react {
    color: #1E1E1E;
    font-size: 14px;
    background-color: #F3F3F3;
    padding: 6px 12px;
    text-decoration: none;
    &-views {
        border-radius: 20px 0px 0px 20px;
    }
    &-print {
        border-radius: 0 20px 20px 0;
    }
}

.article__content {
    border-top: 1px solid #dedee0;
    padding: 20px 0px 0px 83px;
        width: 100%;
        position: relative;
        @include media("<=1300px") {
            padding-left: 0;
            display: flex;
            flex-direction: column;
        }
}

.article__social {
    position: absolute;
    left: 0;
    top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    @include media("<=1300px") {
        position: relative;
        order: 2;
        flex-direction: row;
        column-gap: 10px;
        margin-bottom: 36px;
    }
}

.article__social-link {
width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #F4F4F6;
    transition: all 0.3s ease;
    @include media("<=1300px") {
        min-height: 54px;
    }

    &:hover {
        background-color: #ff2a28;
    }
}

.main {
    max-width: 826px;
    width: 100%;
}

.main__article {
    padding-bottom: 11px;
    @include media("<=1300px") {
        padding-bottom: 60px;
    }
}

.article__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 24px;
    margin-bottom: 24px;
}

.article__image {
    flex: 0 1 100%;
    max-width: 743px;
    width: 100%;
    margin: 16px 0;
    @include media("<=1300px") {
        order: 3;
    }
    img {
        width: 100%;
        display: block;
    }
}

.article__head-content {
    flex: 0 1 100%;
    max-width: 373px;
}

.article__title {
    font-size: 27px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 28px;
}

.article__dscr {
    font-size: 16px;
    line-height: 21px;
    color: #9999A1;
    font-style: italic;
    max-width: 693px;
    @include media("<=1300px") {
        order: 1;
    }
        @include media("<=810px") {
            font-size: 14px;
        }
}

.article__banner {
    margin-top: 15px;
   max-width: 820px;
   img {
    width: 100%;
    display: block;
   }
}

.article__text {
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 36px;
    @include media("<=1300px") {
        order: 4;
    }
        @include media("<=810px")  {
          font-size: 16px;
        }
    p {
        padding-bottom: 10px;
    }
}



