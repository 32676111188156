body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #ffffff;
  color: #1E1E1E;
  position: relative;
  @include media("<=810px") {
    background-position-x: 67%;
    background-size: 380%;
  }
  @include media("<=430px") {
    background-size: 550%;
  }
  &.page {
    background-image: none;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}


.container {
  width: 100%;
  max-width: 1256px;
  margin: 0 auto;
}

.section__title {
  font-size: 18px;
    font-weight: 700;
  line-height: 1.1;
  @include media("<=1080px") {
  }
  @include media("<=810px") {
    
    
  }
  @include media("<=430px") {
    
  }
}

.sidebar__title {
  color: rgb(30, 30, 30);
  font-weight: 700;
  box-sizing: border-box;
      font-size: 18px;
      line-height: 24px;
      width: fit-content;
      position: relative;
      margin-bottom: 20px;
      &::after {
        content: '';
    
        display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 30px;
          border-bottom: 1px solid #1e1e1e;
          z-index: 0;
          transition: all 0.1s ease 0s;
      }
}

.sidebar__title:hover::after {
  width: 100%;
}

.banner {
  margin: 31px auto;
  display: block;
  max-width: 1256px;
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
}

//BURGER BUTTON//
.burger__button {
  display: none;

  

  @include media("<=430px") {
    top: 24px;
    right: 20px;
  }

  

  .line {
    height: 6px;
    width: 58px;
    margin-bottom: 8px;
    background-color: #583F00;
    transition: all 200ms ease-in-out;
    opacity: 1;

    @include media("<=736px") {
      height: 4px;
      width: 48px;
    }
  }

  &.active {
    .line {
      background-color: #583F00;
      &.one {
        transform: rotate(45deg) scale(1.2);
      }
      &.two {
        transform: rotate(-45deg) scale(1.2) translateY(-8px) translateX(8px);
        @include media("<=736px") {
          transform: rotate(-45deg) scale(1.2) translateY(-7px) translateX(7px);
        }
      }
      &.three {
        opacity: 0;
      }
    }
  }
}
