.sidebar {
  flex: 0 1 100%;
  max-width: 392px;
  width: 100%;
  @include media("<=1300px") {
    max-width: 300px;
  }
    @include media("<=1024px") {
      display: none;
    }
}



.sidebar__banner {
  margin: 0 auto 20px;
  max-width: 300px;
  width: 100%;
  img {
    width: 100%;
  }
}

.special__title {
  width: 100%;
  display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
  background: rgba(0, 0, 0, 0.4);
  padding: 26px;
  margin-top: 20px;
}

.sidebar__article {
  margin: 0 0 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 8px;
  font-size: 19px;
  font-weight: 700;
  line-height: 34px;
  background-color: #FFF3E8;
  padding: 30px 39px;
}

.sidebar__author {
  color: #66666D;
  font-size: 13px;
  font-weight: 400;
}

.sidebar__date {
  color: #1E1E1E;
  font-style: italic;
  font-size: 11px;
  font-weight: 400;
  margin-left: auto;
  text-align: right;
  span {
    opacity: 0.5;
    margin-right: 4px;
  }
}

.sidebar__block {
  margin-bottom: 60px;
}

.sidebar__item {
  border-bottom: 1px solid #dedee0;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.sidebar__text {
  color: #1e1e1e;
  text-decoration: none;
  font-size: 15px;
  line-height: 20px;
  padding-top: 12px;
  cursor: pointer;
  &:hover {
    color: #FF2A28;
  }
}

.tag {
  text-transform: lowercase;
    text-decoration: none;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    padding: 0 8px;
    border-radius: 20px;
    background: #1E1E1E;
    margin-right: 11px;
    display: inline-block;
}

.more {
  color: #1E1E1E;
  text-decoration: none;
  display: flex;
  text-align: right;
  margin-top: 20px;
  margin-left: auto;
  width: fit-content;
  text-transform: lowercase;
  &:hover {
    text-decoration: underline;
  }
}

