.footer {
  background: #1E1E1E;
 
  @include media("<=430px") {
    padding: 44px 0;
  }
  
}

.footer__inner {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  color: #9999A1;
  @include media("<=1300px") {
      max-width: 945px;
      margin: 0 auto;
      
    }
  
    @include media("<=1024px") {
      max-width: 750px;
    }
  
    @include media("<=810px") {
      max-width: 90%;
    }
}

.footer__row {
  width: 100%;
  padding: 80px 0;
  border-bottom: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
    padding: 32px 0;
  }
}


.footer__row-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 32px;
  color: #fff;
  @include media("<=810px") {
    text-align: center;
    font-size: 16px;
  }
  @include media("<=430px") {
    
  }
@include media("<=350px") {
  
}
}

.footer__subscribe {
  flex: 0 1 50%;
  @include media("<=1300px") {
    max-width: 408px;
  }
    @include media("<=1024px") {
      max-width: 345px;
    }
    @include media("<=810px") {
      max-width: 100%;
      flex: 0 1 100%;
      width: 100%;
      margin-bottom: 30px;
    }
}

.footer__form {
  border: 1px solid #464648;
  border-radius: 40px;
  height: 54px;
  max-width: 520px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5px 5px 25px;
  @include media("<=810px") {
    max-width: 100%;
  }

  input {
    max-width: 322px;
    width: 100%;
    font-size: 14px;
    background-color: transparent;
    color: #fff;
    border: none;
  }
}

.footer__button {
  border-radius: 40px;
    background: rgb(255, 42, 40);
    color: #fff;
    text-decoration: none;
    text-align: center;
    font-size: 13px;
    padding: 16px 34px;
    
    &:hover {
      background-color: #9999A1;
    }
}

.footer__link {
  display: inline-block;
  width: fit-content;
  font-weight: 400;
  color: #9999A1;
  text-decoration: none;
  font-size: 14px;
    line-height: 22px;
    
    
  @include media("<=430px") {
    font-size: 14px;
    column-gap: 15px;
  }
@include media("<=350px") {
  font-size: 12px;
  justify-content: center;
}
  &:hover {
    color: #fff;
  }
}

.footer__nav {
  display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 23px;
    width: 100%;
    padding-bottom: 21px;
    @include media("<=810px") {
      order: 4;
      flex: 0 1 100%;
    flex-direction: row;
      justify-content: space-around;
      max-width: 600px;
    }
    @include media("<=430px") {
      justify-content: space-between;
    }
}

.footer__main {
  padding: 40px 0 28px;
  position: relative;
  display: flex;
  width: 100%;
  border-top: 1px solid rgb(132, 132, 132);
  border-bottom: 1px solid rgb(132, 132, 132);
  
}

.footer__logo {
  position: relative;
  max-width: 84px;
  width: 100%;  
  flex: 1 0 100%;
  @include media("<=810px") {
  
  }
  @include media("<=430px") {
   
  }

  img {
    width: 100%;
    display: block;
  } 
}

.footer__menu {
  flex: 0 1 50%;
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  @include media("<=1024px") {
    flex-wrap: wrap;
    row-gap: 20px;
  }
    @include media("<=810px") {
      flex: 0 1 100%;
      width: 100%;
      margin-bottom: 30px;
    }
}

.footer__column {
  display: flex;
  flex-direction: column;
  
}

.column__title {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

.column__nav {
  margin-top: 10px;
  display: flex;
    flex-direction: column;
   
}

.footer__right {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
  align-items: center;
}

.footer__social {
  flex: 0 1 50%;
  @include media("<=1300px") {
    max-width: 434px;
  }
    @include media("<=1024px") {
      max-width: 345px;
    }
    @include media("<=810px") {
      max-width: 100%;
      flex: 0 1 100%;
      width: 100%;
    }
}

.footer__media {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 32px;
  @include media("<=1024px") {
    column-gap: 10px;
  }
    @include media("<=810px") {
      justify-content: space-between;
    }
  .social__link {
      width: 54px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background-color: #fff;
      transition: all 0.3s ease;
  
      &:hover {
        background-color: #9999A1;
      }
    }

        .icon-fb {
          mask: url(../images/icon-fb.svg);
          width: 7px;
          height: 14px;
        }
    
        .icon-telegram {
          mask: url(../images/icon-telegram.svg);
          width: 17px;
          height: 14px;
        }
    
        .icon-twitter {
          mask: url(../images/icon-twitter.svg);
          width: 17px;
          height: 14px;
        }
    
        .icon-youtube {
          mask: url(../images/icon-youtube.svg);
          width: 18px;
          height: 14px;
        }
    
        .icon-rss {
          mask: url(../images/icon-rss.svg);
          width: 14px;
          height: 14px;
        }
    
        .icon {
          display: inline-block;
          background: #1E1E1E;
          mask-size: contain;
          mask-position: center;
          mask-repeat: no-repeat;
          transition: all 0.3s ease;
        }
    .social__link:hover .icon {
      background: #fff;
    }    
}

.footer__left {
  display: flex;
  column-gap: 32px;
  max-width: 577px;
  width: 100%;
  font-size: 11px;
  line-height: 16px;
  @include media("<=810px") {
    max-width: 100%;
    margin-bottom: 20px;
  }
  p {
    padding-bottom: 10px;
  }
}

.footer__mail {
  font-size: 13px;
    padding: 0 5px;
    line-height: 19px;
    color: #fff;
    text-decoration: none;
}

.footer__text {
  max-width: 577px;
  font-size: 11px;
  line-height: 16px;
  width: 100%;
  @include media("<=810px") {
    max-width: 100%;
  }
}

.copyright {
  font-size: 13px;
  padding: 0 5px;
  line-height: 19px;
  color: #fff;
}

.partners {
  margin: 30px auto 5px;
  display: flex;
  justify-content: center;
  column-gap: 3px;
}

.authors {
  display: flex;
  column-gap: 20px;
  position: absolute;
  bottom: 14px;
  right: 0;
  font-size: 10px;
  p {
    max-width: 84px;
  }
}

.cookies {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 15px 15px;
  row-gap: 30px;
  font-size: 13px;
  color: #1E1E1E;
  text-align: center;
}