.related {
    margin-bottom: 80px;
    @include media("<=1300px") {
            max-width: 945px;
            margin: 0 auto 80px;
    
        }
    
        @include media("<=1024px") {
            max-width: 750px;
        }
    
        @include media("<=810px") {
            max-width: 90%;
        }
}

.related__inner {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    padding-top: 43px;
    padding-bottom: 33px;
    flex-wrap: wrap;
    margin: 0 auto;
    row-gap: 20px;
    
}

.related__item {
    display: flex;
    flex-direction: column;
    flex: 0 1 31.3%;
    max-width: 392px;
    font-size: 15px;
    line-height: 20px;
    text-decoration: none;
    color: #333;
    padding-bottom: 20px;
    border-bottom: 1px solid #dedee0;
    @include media("<=1024px") {
        flex: 0 1 48%;
    }
        @include media("<=810px") {
            flex: 0 1 100%;
            max-width: 100%;
            font-size: 13px;
        }
}

.related__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.related__date {
   font-style: italic;
    font-size: 12px;
    font-weight: 500;
        opacity: .5;
  
}

.related__title {
 color: #1E1E1E;
 font-size: 14px;
 font-weight: 500;
 line-height: 22px;
 text-decoration: none;
 margin-bottom: 10px;
@include media("<=810px") {
    font-size: 13px;
}
 &:hover {
    color: #FF2A28;
 }
}

.related__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.related__authors {
    color: #66666D;
    font-size: 14px;
    line-height: 30px;
    @include media("<=810px") {
        font-size: 12px;
    }
}

.related__views {
    font-size: 14px;
        line-height: 18px;
        background: #F3F3F3;
        color: #1e1e1e;
        padding: 6px 12px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        @include media("<=810px") {
                font-size: 12px;
            }
        &:before {
          content: "";
          width: 14px;
          height: 18px;
          margin-right: 5px;
        }
}