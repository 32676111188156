.comments {
    max-width: 826px;
    @include media("<=1024px") {
            max-width: 750px;
            margin: 0 auto;
        }
    
        @include media("<=810px") {
            max-width: 90%;
        }
}

.comments__head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 104px;
    
    margin-bottom: 17px;
}

.comments__title {
    font-size: 18px;
    font-weight: 700;
}

.comments__count {
    color: #9999A1;
    font-size: 13px;
    text-align: right;
}

.comments__capcha {
    flex: 0 1 100%;
    
    font-size: 13px;
    text-align: right;
    img {
        margin-top: 10px;
        margin-left: auto;
        display: block;
    }
}

.comments__input {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dedee0;
    input {
        margin-top: 5px;
        padding: 12px 0 12px 5px;
        font-size: 14px;
            line-height: 18px;
            color: #9999A1;
            border: none;
            border-bottom: 1px dotted #dedee0;
    }
}

.comments__button {
    display: block;
    max-width: 150px;
    margin: 20px 0 20px auto;
    border-radius: 40px;
        background: #9999A1;
        color: #fff;
        text-decoration: none;
        text-align: center;
        font-size: 13px;
        padding: 16px 34px;
    
        &:hover {
            background-color: rgb(255, 42, 40);
        }
}

.comments__inner {
    
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 13px;
    margin-bottom: 34px;
}

.comments__active {
    width: 100%;
    display: flex;
    column-gap: 8px;

    textarea {
        width: 100%;
        max-width: 748px;
        resize: none;
        border: 1px solid rgb(211, 214, 219);
    }
}

.comments__item {
    width: 100%;
}

.comments__main {
  width: 100%;
    display: flex;
    column-gap: 44px;
    @include media("<=810px") {
        column-gap: 20px;
    }
        @include media("<=430px") {
            column-gap: 10px;
        }
}

.comments__content {
    font-size: 15px;
    line-height: 28px;
    color: #757575;
}

.comments__name {
    color: #715F5F;
    font-weight: 700;
    margin-bottom: 4px;
}

.sponsor {
    max-width: 1256px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 120px;
    background: rgb(255, 234, 234);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 88px 13px 47px;
    @include media("<=1300px") {
            max-width: 945px;
            margin: 0 auto 120px;
            column-gap: 20px;
            padding: 40px 3.8%;
        }
    
        @include media("<=1024px") {
            max-width: 750px;
            margin-bottom: 100px;
        }
    
        @include media("<=810px") {
            max-width: 100%;
            flex-direction: column;
            align-items: center;
            row-gap: 20px;
            padding: 32px 15px;
        }
}

.sponsor__title {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 1px solid rgb(43, 57, 123);
    @include media("<=810px") {
        text-align: center;
    }
}

.sponsor__content {
    max-width: 444px;
    @include media("<=810px") {
        text-align: center;
        max-width: 600px;
    }
    
}

.sponsor__text {
    padding: 12px 0 24px;
    font-size: 14px;
    line-height: 22px;
}

.sponsor__image {
    max-width: 540px;
    img {
        width: 100%;
        display: block;
    }
}

.sponsor__button {
    display: block;
        max-width: 155px;
        border-radius: 40px;
        background: rgb(30, 30, 30);
        color: #fff;
        text-decoration: none;
        text-align: center;
        font-size: 13px;
        padding: 16px 34px;
        @include media("<=810px") {
            margin: 0 auto;
        }
        &:hover {
            background-color: rgb(255, 42, 40);
        }
}