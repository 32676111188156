.header {
  width: 100%;
  position: relative;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
  }
  &.page {
    position: relative;
  }
}

.header__inner {
  
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 20;
  
}

.header__date {
  color: #797979;
  font-size: 13px;
  margin-left: auto;
  margin-right: 40px;
}

.header__menu {
  
  width: 100%;
  &-top {
    background: rgb(244, 244, 246);
  }
  &-bottom {
    border-bottom: 1px solid #1e1e1e;
  }
}

.header__nav {
  flex: 0 1 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  @include media("<=1080px") {
    
  }
  
}

.header__link {
  font-size: 13px;
    font-weight: 400;
    text-decoration: none;
    transition: all 0.3s ease;

}

.header__nav-top {
   padding: 12px 0;
  justify-content: center;
  column-gap: 39px;
  @include media("<=1300px") {
      max-width: 950px;
      margin: 0 auto;
    }
    @include media("<=1024px") {
      max-width: 775px;
      margin: 0 auto;
      justify-content: space-between;
      column-gap: 5px;
    }
        @include media("<=810px") {
         display: none;
        }

.header__link {
  color: #1E1E1E;
  position: relative;
  height: 100%;
  line-height: 16px;
  font-size: 10px;
  @include media("<=810px") {
  }

  &:hover {
    color: #ff2a28;
    
  }
 &-active {
  color: #ff2a28;
 }
 &-bold {
  font-weight: 700;
 }
}

.header__link:last-of-type::after {
  display: none;
}

.header__link:hover .icon {
  background: #fff;
}



.icon:hover {
  background: #fff;
}

.header__social {
  height: 100%;
  display: flex;
  align-items: center;
}

.social__link {
  width: 40px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  &:hover {
    background: #df2027;
  }
}

.social__link:hover .icon {
  background: #fff;
}

.icon-tv {
  mask: url(../images/icon-tv.svg);
  width: 24px;
  height: 24px;
  background: #333;
}

.icon-fb {
  mask: url(../images/icon-fb.svg);
  width: 7px;
  height: 14px;
}

.icon-telegram {
  mask: url(../images/icon-telegram.svg);
  width: 17px;
  height: 14px;
}

.icon-twitter {
  mask: url(../images/icon-twitter.svg);
  width: 17px;
  height: 14px;
}

.icon-youtube {
  mask: url(../images/icon-youtube.svg);
  width: 18px;
  height: 14px;
}
}



.header__nav-bottom {
  column-gap: 12px;
  padding: 5px 0;
  @include media("<=1300px") {
      max-width: 950px;
      margin: 0 auto;
      justify-content: flex-start;
    }
     @include media("<=1024px") {
       max-width: 775px;
       margin: 0 auto;
       
     }
     @include media("<=810px") {
       max-width: 90%;

     }

  .header__link {
    text-transform: lowercase;
      color: #1E1E1E;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 22px;
      border-bottom: 1px solid transparent;
  
      
  
      &:hover {
        border-bottom: 1px solid #1E1E1E;
      }
            &:last-of-type {
              margin-left: 20px;
              
            }
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6),
      &:nth-of-type(7) {
      @include media("<=1300px") {
        display: none;
      }
      }
            &:nth-of-type(1),
            &:nth-of-type(2) {
              @include media("<=1024px") {
                display: none;
              }
            }
    }

        .header__link:last-of-type::before {
          content: '';
          position: absolute;
          left: -20px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 18px;
          background-color: #dedee0;
        }
}



.header__links {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  @include media("<=1300px") {
    margin-left: 35px;
  }
    @include media("<=810px") {
      display: none;
    }
}

.header__buttons {
  display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
    @include media("<=1300px") {
      margin-left: auto;
    }
}

.header__button {
  color: #1E1E1E;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 22px;
    border-radius: 10px;
    text-decoration: none;
    font-size: 13px;
    height: 48px;
    transition: all 0.3s ease;
}

.header__button-pink {
  color: #FF2A28;
  padding: 0 19px;
  background-color: rgb(255, 234, 234);

@include media("<=810px") {
    display: none;
  }

  &:hover {
    background-color: #FF2A28;
    color: #fff;
  }
}

.header__button-search {
  background-color: #F4F4F6;
  width: 48px;
  &:hover {
      background-color: #1e1e1e;
      color: #fff;
    }
}

.header__button-search:hover .icon {
  background: #f4f4f6;
}

.icon-search {
  mask: url(../images/icon-search.svg);
  width: 20px;
  height: 20px;
}

.icon {
  display: inline-block;
  background: #1e1e1e;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  transition: all 0.3s ease;
}

.header__button-burger {
  width: 48px;
  background-color: #1e1e1e;
  row-gap: 6px;
  flex-direction: column;
  span {
    width: 26px;
    height: 1px;
    background-color: #fff;
  }
}

.header__logo {
  max-width: 199px;
  width: 100%;
  
}

.logo {
  display: block;
  max-width: 199px;
  width: 100%;
  flex: 0 1 100%;
  font-size: 0;
  line-height: 0;
  text-decoration: none;
  @include media("<=810px") {
    position: relative;
    z-index: 21;
  }
    @include media("<=430px") {
      max-width: 168px;
    }
  img {
    width: 100%;
    display: block;
  }
}




