@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
    font-family: 'HeliosCond';
    src: url('../fonts/3971-font-webfont.eot');
    src: url('../fonts/3971-font-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/3971-font-webfont.woff') format('woff'),
        url('../fonts/3971-font-webfont.ttf') format('truetype'),
        url('../fonts/3971-font-webfont.svg#helioscondbold') format('svg');
    font-weight: 700;
    font-style: normal;
}